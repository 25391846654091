<template>
	<div class="fs-b login_warp">
		<div class="top_img"><div class="title">欢迎登录校园智慧后勤</div></div>

		<van-form class="form_wrap color_ffffff_bg">
			<div class="login_text_wrap">
				<div class="login_text fs_18">账号登录</div>
				<div class="login_split gradient"></div>
			</div>
			<van-field
				v-model="username"
				class="form_item"
				name="name"
				label-width="70px"
				label="手机号码"
				placeholder="手机号码"
				:rules="[{ required: true, message: '请填写手机号码' }]"
			/>
			<div class="pwd_wrap">
				<van-field
					v-model="password"
					class="form_item pwd"
					name="pwd"
					label-width="70px"
					label="验证码"
					placeholder="验证码"
					:rules="[{ required: false, message: '请填写验证码' }]"
				/>
				<div class="pwd_btn gradient" @click="getCode">{{ code }}</div>
			</div>
			<div style="margin:0 20px  10px 20px">
				<van-button round block color="linear-gradient(to right, #27a738, #1eaa72)" type="info" :loading="isSubDisable" :disabled="isSubDisable" @click="onSubmit">
					登录
				</van-button>
			</div>
		</van-form>
		<div class="bottom_img"></div>
	</div>
</template>
<script>
import { wxLogin, getCode, bindUser } from '@/api/login.js';
import { Toast } from 'vant';
// import axios from "axios";
export default {
	props: {},
	components: {},
	data() {
		return {
			msg: 'Hello World! 登录界面',
			username: '',
			password: '',
			code: '获取验证码',
			time: 0,
			tOut: null,
			isCodeDisabled: false,
			isSubDisable: false
		};
	},
	created() {},
	destroyed() {
		clearTimeout(this.tOut);
	},
	mounted() {},
	methods: {
		// 获取url的参数
		getQueryVariable(variable) {
			var query = window.location.search.substring(1);
			var vars = query.split('&');
			for (var i = 0; i < vars.length; i++) {
				var pair = vars[i].split('=');
				if (pair[0] == variable) {
					return pair[1];
				}
			}
			return false;
		},
		// 获取验证码
		getCode() {
			if (this.isCodeDisabled) {
				return;
			}
			if (this.username) {
				this.time = 60;
				this.code = '60重新获取';
				this.isCodeDisabled = true;
				this.subCode(this.time);

				// {"openId":"openId","phone":"15290835078","tenantId":"497733","miniApp":false}
				const tenantId = localStorage.getItem('tenantId') || '000';
				const openId = this.$cookies.get('openId') || '000';
				const params = { openId: openId, phone: this.username, tenantId: tenantId, miniApp: false };
				getCode(params).then(res => {
					// console.log(res)
					if (res.code == 200) {
						// console.log(res)
					}
				});
			}
		},
		subCode(num) {
			this.tOut = setTimeout(() => {
				this.time--;
				this.code = `${num--}重新获取`;
				if (num > 0) {
					this.subCode(num);
				} else {
					this.code = '获取验证码';
					this.isCodeDisabled = false;
				}
			}, 1000);
		},
		// 登录
		/**
		 * 手机号、验证码、openid
		 * 返回用户信息和   token 存到localstorage
		 */
		onSubmit() {
			const tenantId = localStorage.getItem('tenantId');
			const access_token = localStorage.getItem('access_token');
			const openId = this.$cookies.get('openId');
			const params = { openId: openId, code: this.password, phone: this.username, tenantId: tenantId, miniApp: false, access_token: access_token };
			this.isSubDisable = true;
			bindUser(params)
				.then(res => {
					// console.log(res)
					this.isSubDisable = false;
					if (res.code == 200) {
						localStorage.setItem('token', res.token);
						localStorage.setItem('userInfo', JSON.stringify(res.userInfo));
						if (window.history.length <= 1) {
							this.$router.replace({ name: 'homeIndex' });
							return false;
						} else {
							this.$router.replace({ name: 'homeIndex' });
						}
					} else {
						Toast.fail(res.message);
					}
				})
				.fail(() => {
					this.isSubDisable = false;
				});
		},

		weChart() {
			const code = this.getQueryVariable('code');
			if (code) {
				// console.log(code)
				wxLogin({ code }).then(res => {
					console.log(res);
				});
			}
		},

		wxBind() {
			// axios.get('https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=wxd21e3ea9737083fc&secret=299bc095201a4b67a8037bef87d0e0c6').then(res => {
			//   // // console.log(res)
			// })
		}
	}
};
</script>
<style lang="scss" scoped>
/deep/ .van-field__label {
	color: #20a967 !important;
	font-size: 16px;
}
.gradient {
	background-image: linear-gradient(to right, #27a738, #1eaa72);
}
.login_warp {
	height: 100vh;
	background-image: linear-gradient(#f6f6f6, #ffffff);
	position: relative;
	// 背景图
	.top_img {
		background-image: url('../../assets/image/top.png');
		background-size: 100% 100%;
		height: 45%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		// 名称
		.title {
			z-index: 10;
			margin: 0 auto;
			height: 20px;
			font-size: 20px;
			font-weight: 400;
			color: #ffffff;
			line-height: 20px;
			margin-top: 86px;
			text-align: center;
		}
	}

	// form 表单
	.form_wrap {
		box-shadow: 0px 8px 10px 2px #ebefec;
		border-radius: 10px;
		width: 80%;
		height: 300px;
		position: absolute;
		left: 10%;
		top: 22%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		.login_text_wrap {
			margin: 10px 25px 0 25px;
			.login_text {
				color: #20a966;
				line-height: 18px;
			}
			.login_split {
				width: 34px;
				height: 3px;
				margin-top: 3px;
			}
		}

		.form_item {
			margin: 0 7px;
			width: auto;
		}
		// padding: 8px;
		.pwd_wrap {
			display: flex;
			color: #ffffff;
			.pwd {
				flex: 1;
			}
			.pwd_btn {
				width: 85px;
				text-align: center;
				line-height: 44px;
				margin-right: 16px;
				font-size: 12px;
			}
		}
	}
	.bottom_img {
		background-image: url('../../assets/image/bottom.png');
		background-size: 100% 100%;
		height: 30%;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
}
</style>
